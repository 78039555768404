exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/AboutUs/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-media-js": () => import("./../../../src/pages/AboutUs/media.js" /* webpackChunkName: "component---src-pages-about-us-media-js" */),
  "component---src-pages-blogs-blog-js": () => import("./../../../src/pages/blogs/blog.js" /* webpackChunkName: "component---src-pages-blogs-blog-js" */),
  "component---src-pages-events-event-js": () => import("./../../../src/pages/events/event.js" /* webpackChunkName: "component---src-pages-events-event-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-noevents-js": () => import("./../../../src/pages/events/noevents.js" /* webpackChunkName: "component---src-pages-events-noevents-js" */),
  "component---src-pages-events-want-more-in-wela-js": () => import("./../../../src/pages/events/WantMoreInWela.js" /* webpackChunkName: "component---src-pages-events-want-more-in-wela-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-get-a-demo-index-js": () => import("./../../../src/pages/GetADemo/index.js" /* webpackChunkName: "component---src-pages-get-a-demo-index-js" */),
  "component---src-pages-home-ads-js": () => import("./../../../src/pages/Home/Ads.js" /* webpackChunkName: "component---src-pages-home-ads-js" */),
  "component---src-pages-home-blogs-js": () => import("./../../../src/pages/Home/Blogs.js" /* webpackChunkName: "component---src-pages-home-blogs-js" */),
  "component---src-pages-home-blogs-scroll-js": () => import("./../../../src/pages/Home/BlogsScroll.js" /* webpackChunkName: "component---src-pages-home-blogs-scroll-js" */),
  "component---src-pages-home-features-feature-one-js": () => import("./../../../src/pages/HomeFeatures/FeatureOne.js" /* webpackChunkName: "component---src-pages-home-features-feature-one-js" */),
  "component---src-pages-home-features-feature-zero-js": () => import("./../../../src/pages/HomeFeatures/FeatureZero.js" /* webpackChunkName: "component---src-pages-home-features-feature-zero-js" */),
  "component---src-pages-home-features-home-features-js": () => import("./../../../src/pages/HomeFeatures/HomeFeatures.js" /* webpackChunkName: "component---src-pages-home-features-home-features-js" */),
  "component---src-pages-home-footer-js": () => import("./../../../src/pages/Home/Footer.js" /* webpackChunkName: "component---src-pages-home-footer-js" */),
  "component---src-pages-home-home-about-us-js": () => import("./../../../src/pages/Home/HomeAboutUs.js" /* webpackChunkName: "component---src-pages-home-home-about-us-js" */),
  "component---src-pages-home-home-nav-bar-js": () => import("./../../../src/pages/Home/HomeNavBar.js" /* webpackChunkName: "component---src-pages-home-home-nav-bar-js" */),
  "component---src-pages-home-home-testimonial-js": () => import("./../../../src/pages/Home/HomeTestimonial.js" /* webpackChunkName: "component---src-pages-home-home-testimonial-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-subscribe-modal-js": () => import("./../../../src/pages/Home/SubscribeModal.js" /* webpackChunkName: "component---src-pages-home-subscribe-modal-js" */),
  "component---src-pages-home-trusted-by-js": () => import("./../../../src/pages/Home/TrustedBy.js" /* webpackChunkName: "component---src-pages-home-trusted-by-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/Testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */),
  "component---src-pages-testimonials-testimons-js": () => import("./../../../src/pages/Testimonials/Testimons.js" /* webpackChunkName: "component---src-pages-testimonials-testimons-js" */)
}

